@import "normalize.css";

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
p {
	margin: 0;
}
*[hidden] {
	display: none !important;
}

.form {
	margin-top: 15px;
	padding-top: 1px;
	&__group {
		position: relative;
		padding-left: 30px;
	}
	&__group + &__group {
		margin-top: 20px;
	}
	&__group-title {
		font-size: 14px;
		line-height: 20px;
		color: #4e4e4e;
		position: absolute;
		top: 0;
		left: 0;
	}
	&__field {
		margin-top: 10px;
	}
	&__label {
		font-size: 14px;
		line-height: 20px;
		color: #383838;
		margin-bottom: 3px;
		display: inline-block;
	}
	&__input,
	&__select {
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
		background-color: #fff;
		background-repeat: no-repeat;
		margin: 0;
		outline: 0;
		border: 1px solid #dfdfdf;
		height: 35px;
		width: 100%;
		padding: 0 10px;
		box-sizing: border-box;
		color: #333;
		transition: 0.3s;
		font-size: 15px;
	}
	&__select {
		cursor: pointer;
	}
	&__submit {
		width: 100%;
		box-sizing: border-box;
	}
}
.button {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	background: transparent;
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	transition: 0.3s;
	cursor: pointer;
	position: relative;
	z-index: 1;
	text-align: center;
	display: table;
	font-weight: 500;
	&_l {
		padding: 12px 20px;
	}
	&_red {
		background-color: #ff373e;
		color: #fff;
	}
}
.game {
	&-footer {
		margin-top: 20px;
		& .button {
			display: inline-block;
			margin-right: 15px;
		}
	}
	&-status {
		padding: 20px;
	}
	&-choice {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 15px;
		& * + * {
			margin-left: 10px;
		}
	}
	&-body {
		margin-top: 15px;
		margin-bottom: 15px;
	}
	&__success,
	&__fail {
		border-left: 2px solid transparent;
		padding: 10px 15px;
		margin-bottom: 15px;
	}
	&__success {
		background: rgba(29, 188, 97, 0.05);
		border-color: #1dbc61;
	}
	&__fail {
		background: rgba(255, 55, 62, 0.05);
		border-color: #ff373e;
	}
	&-settings {
		& p + p {
			margin-top: 5px;
		}
	}
}
.rules {
	border: 2px dashed #eee;
	padding: 20px;
	&-header {
		&__title {
			font-weight: bold;
			font-size: 18px;
		}
	}
	&-items {
		margin-top: 15px;
	}
	&-item {
		& + & {
			margin-top: 10px;
		}
	}
	& p + p {
		margin-top: 15px;
	}
	& ul {
		list-style: none;
		margin: 0;
		padding: 0;
		& li {
			padding-left: 12px;
			position: relative;
			margin-top: 3px;
			margin-left: 8px;
			&:before {
				content: "";
				background: #ff373e;
				height: 5px;
				width: 5px;
				left: 0;
				top: 8px;
				position: absolute;
			}
		}
	}
}

.shape {
	background-size: 90%;
	background-position: center center;
	background-repeat: no-repeat;
	width: 64px;
	height: 64px;
	display: inline-block;
	vertical-align: middle;
	&_small {
        background-size: 55%;
    }
    &_round {
	    background-image: url("../assets/triangle.svg");
	}
	&_round.shape_black {
	    background-image: url("../assets/triangle-filled.svg");
	}
	&_square {
	    background-image: url("../assets/square.svg");
	}
	&_square.shape_black {
	    background-image: url("../assets/square-filled.svg");
	}
}
